<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-form ref="form" :model="form">
          <el-col :span="6">
            <el-form-item>
              <el-input
                clearable
                placeholder="请输入关键字"
                v-model="form.searchValue"
                @keyup.enter.native="searchEvent"
              />
            </el-form-item>
          </el-col>
        </el-form>
        <el-col :span="18" style="text-align: right">
          <el-button @click="changeaddDialog(true)" class="search-button"
            >{{ $t("add") }}</el-button
          >
          <el-button @click="searchEvent" class="search-button">{{
            $t("search")
          }}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{
            $t("clean")
          }}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        border
      >
        <el-table-column
          prop="sort"
          align="center"
          header-align="center"
          min-width="100"
          label="顺序"
        >
        </el-table-column>
        <el-table-column
          prop="typeName"
          min-width="148"
          align="center"
          label="分类名称"
        >
        </el-table-column>
        <el-table-column
          prop="countNum"
          min-width="168"
          align="center"
          label="视频数量"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.countNum">
              {{ scope.row.countNum }}
            </span>
            <span v-else> 0 </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="top"
          align="center"
          min-width="168"
          label="是否置顶"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.top === 1"> 是 </span>
            <span v-else> 否 </span>
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="120" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="changeaddDialog(true, true, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              style="color: #e02020"
              @click="toDel(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-con">
        <div class="page-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="form.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="form.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 编辑确认弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      :fileData="addDialog.data"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeaddDialog"
    ></add-dialog>
    <!-- 删除分类弹窗 -->
    <del-dialog
      ref="delDialog"
      :show="delDialog.visible"
      @closeDialog="changeDelDialog"
      @toSureDialog="sureDeleteDialog"
    ></del-dialog>
  </div>
</template>
<script>
import addDialog from "./dialog/addDialog.vue";
import delDialog from "./dialog/delDialog.vue";
import UserSearchClass from "./userSearchClass";
import apis from "@/apis";
import mixin from "@/mixins/index";
import { mapState } from "vuex";

export default {
  components: { addDialog, delDialog },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
        data: {},
      },
      delDialog: {
        visible: false,
      },
      id: "",
      selectIds: [],
    };
  },
  computed: {
    ...mapState(["Language", "TOKEN"]),
    isDelete() {
      return this.selectIds.length > 0;
    },
  },
  methods: {
    /**
     * @function 打开编辑弹窗
     * @param {String} fileName 文件名
     */
    openaddDialog(data) {
      this.addDialog.visible = true;
      const { userName, phonenumber, userId } = data;
      this.changeaddDialog(true, { userName, phonenumber, userId });
    },
    changeaddDialog(dialogStatus, isEdit = false, data = {}) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          this.addDialog.data = data;
          this.$refs.addDialog.getData(data);
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          // this.$refs["addDialog"].getDataQuery(data);
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    changeDelDialog() {
      this.delDialog.visible = false;
    },
    /**
     * @function 点击删除按钮获取当前项id
     */
    toDel(row) {
      this.delDialog.visible = true;
      this.id = row.id;
    },
    toDelete() {
      console.log(this.selectIds);
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http
        .post(apis.videoClassificationList, {
          ...this.form,
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
          }
        });
    },
    sureConfirmDialog() {},
    sureDeleteDialog() {
      this.$http
        .delete(`${apis.deleteCourseType}?typeId=${this.id} `)
        .then((res) => {
          if (res.data.code === 0) {
            this.delDialog.visible = false;
            this.searchEvent();
          }
        });
    },
    /**
     * @function 清空输入框
     */
    cleanForm() {
      this.form = new UserSearchClass("form");
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.table-container {
  .bottom-con {
    text-align: right;
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
