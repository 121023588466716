<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}分类`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form
        ref="form"
        :model="form"
        :inline="true"
        :rules="rules"
        label-width="120px"
      >
        <el-form-item label="分类名称：" prop="typeName">
          <el-input
            clearable
            v-model="form.typeName"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="sort">
          <el-input
            clearable
            v-model="form.sort"
            placeholder="请输入"
            :maxlength="5"
          ></el-input>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialog";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            const editParams = {
              id: this.form.id,
              typeName: this.form.typeName,
              sort: this.form.sort,
            };
            this.$http
              .put(apis.updateCourseType, { ...editParams })
              .then((res) => {
                if (res.data.code === 0) {
                  // 调用关闭方法，不要再写一次
                  this.closeDialog();
                }
              });
          } else {
            const params = {
              ...this.form,
            };
            this.$http.post(apis.addCourseType, { ...params }).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.closeDialog();
              }
            });
          }
        }
      });
      console.log(`我执行了点确定的业务需求`);
    },
    getData(data) {
      this.form = Object.assign({}, data);
    },
  },
};
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
