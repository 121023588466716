<template>
  <div class="dialog-container">
    <el-dialog
      title="删除分类"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="400px"
    >
      <div class="dialog-content">删除后该分类下商品全部归位未分类状态</div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["DROPDOWNBOX"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      //   this.$refs.form.resetFields();
      //   this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 确定删除
     */
    sureEvent() {
      this.$emit("toSureDialog");
    },
  },
};
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.dialog-content {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #151515;
}
</style>
