class searchFrom {
  /* eslint no-underscore-dangle: 0 */
  validateSort = (rule, value, callback) => {
    // const sort = value.replace(/\s/g, "");//去除空格
    //校验排序
    const reg = /^[1-9]\d*$/;
    if (!value) {
        callback([new Error('请输入排序')]);
    } else if (!reg.test(value)) {
        callback([new Error('排序输入不合法')]);
   } else {
       callback()
   }
}

  constructor(type) {
    if (type === 'form') {
      this.typeName = ''
      this.sort = ''
    } else if (type === 'rule') {
      this.typeName = [
        {
          required: true,
          message: '请输入分类名称',
          trigger: 'blur',
        },
      ]
      this.sort = [
          {
            required: true,
            validator: this.validateSort,
            trigger: ['blur', 'change'],
          },
        ]
    }
  }
}
export default searchFrom
